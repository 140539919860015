import React from "react";
import { graphql } from "gatsby";
import Wrapper from "../components/wrapper";
import Flex from "../components/flex";
import Box from "../components/box";
import ArtistRow from "../components/artistrow";
import { Router, Location } from "@reach/router";
import { Helmet } from "react-helmet";
import Button from "../components/button";
import PageH1 from "../components/pageh1";

import ReactMarkdown from "react-markdown";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import { motion, AnimatePresence } from "framer-motion";

import ArtistLinks from "../components/artistlinks";

const FramerRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div style={{ position: "relative" }}>
        <AnimatePresence exitBeforeEnter>
          <Router key={location.key} location={location} primary={false}>
            {children}
          </Router>
        </AnimatePresence>
      </div>
    )}
  </Location>
);
const ArtistPage = ({ slug, artists }) => {
  const { language } = useI18next();

  return (
    <motion.div
      key="1"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Flex justifyContent="center" my={5} position="relative">
        {artists
          .filter((artist) => artist.slug === slug)
          .map((filteredPerson) => (
            <Box key={filteredPerson.name}>
              <Helmet>
                <title>{filteredPerson.name} - La Maison Mère</title>
              </Helmet>
              <Wrapper>
                <Flex width={1}>
                  <Box width={[6 / 7, 1]}>
                    <Box width={[1, 1 / 2]} mx="auto">
                      <img
                        src={filteredPerson.image}
                        style={{ maxWidth: "100%" }}
                        alt={filteredPerson.image.alt}
                      />
                      <Box textAlign="center" as="h1" my={[3, 5]}>
                        {filteredPerson.name}
                      </Box>
                      {filteredPerson.categories && (
                        <Box
                          textAlign="center"
                          as="h3"
                          my={[4, 5]}
                          fontSize={["20px", null]}
                        >
                          {filteredPerson.categories}
                        </Box>
                      )}
                    </Box>
                    <Box
                      maxWidth={"75ch"}
                      mx="auto"
                      className="artistPageContent"
                    >
                      <ReactMarkdown>{filteredPerson.content}</ReactMarkdown>
                    </Box>

                    {filteredPerson.musique && (
                      <Box
                        maxWidth={"75ch"}
                        mx="auto"
                        mt={5}
                        py={5}
                        style={{
                          borderTop: "1px solid rgba(255,255,255,0.1)",
                        }}
                      >
                        <Box>
                          <Box as="h3" mb={5} fontSize={["20px", null]}>
                            Musique
                          </Box>
                        </Box>

                        <Box>
                          {filteredPerson.musique.map((row, i) => (
                            <Flex
                              key={i}
                              mb={3}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Box>{row.title}</Box>
                              {row.link && (
                                <Button as="a" href={row.link} target="_blank">
                                  Écouter
                                </Button>
                              )}
                            </Flex>
                          ))}
                        </Box>
                      </Box>
                    )}
                    {filteredPerson.dossier && (
                      <Box
                        maxWidth={"75ch"}
                        mx="auto"
                        mt={5}
                        py={5}
                        style={{
                          borderTop: "1px solid rgba(255,255,255,0.1)",
                        }}
                      >
                        <Flex justifyContent="space-between" flexWrap="wrap">
                          <Box>
                            <Box as="h3" mb={[5, 0]} fontSize={["20px", null]}>
                              Dossier de presse
                            </Box>
                          </Box>
                          <Box>
                            <Button
                              as="a"
                              href={filteredPerson.dossier}
                              target="_blank"
                            >
                              {language === "en" ? "View" : "Voir"}
                            </Button>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                    {filteredPerson.photos && (
                      <Box
                        maxWidth={"75ch"}
                        mx="auto"
                        mt={5}
                        py={5}
                        style={{
                          borderTop: "1px solid rgba(255,255,255,0.1)",
                        }}
                      >
                        <Box>
                          <Box as="h3" mb={5} fontSize={["20px", null]}>
                            Photos
                          </Box>
                        </Box>
                        <Flex
                          justifyContent="space-between"
                          flexWrap="wrap"
                          mx={-3}
                        >
                          {filteredPerson.photos.map((image, i) => (
                            <Box key={i} width={[1, 1 / 2]} px={3} mb={3}>
                              <img
                                src={image.image}
                                style={{ maxWidth: "100%" }}
                                alt={image.text}
                              />
                              <Box>{image.text}</Box>
                            </Box>
                          ))}
                        </Flex>
                      </Box>
                    )}
                  </Box>
                  {filteredPerson.links && (
                    <ArtistLinks links={filteredPerson.links} />
                  )}
                </Flex>
              </Wrapper>
            </Box>
          ))}
      </Flex>
    </motion.div>
  );
};

const Home = ({ artists, title }) => {
  const { t } = useTranslation();
  return (
    <motion.div
      key="2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Helmet>
        <title>{t("title")} - La Maison Mère</title>
      </Helmet>
      <Box my={5}>
        <PageH1>{t("title")}</PageH1>
      </Box>
      <ArtistRow artists={artists} title={title} />
    </motion.div>
  );
};

const ArtistesPage = ({ data }) => {
  const { t, language } = useI18next();
  const artists = t("artistes");

  return (
    <Layout bg="#211A1A">
      <Flex alignItems="center">
        <Wrapper color="#fafaf7">
          <Home
            path={language === "en" ? "/en/artistes" : "/artistes"}
            artists={artists}
            title={t("title")}
          />
        </Wrapper>
      </Flex>
    </Layout>
  );
};

export default ArtistesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["artistes"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
